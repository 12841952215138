exports.components = {
  "component---src-pages-403-js": () => import("./../../../src/pages/403.js" /* webpackChunkName: "component---src-pages-403-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-blog-editor-js": () => import("./../../../src/pages/admin/blog-editor.js" /* webpackChunkName: "component---src-pages-admin-blog-editor-js" */),
  "component---src-pages-admin-blog-js": () => import("./../../../src/pages/admin/blog.js" /* webpackChunkName: "component---src-pages-admin-blog-js" */),
  "component---src-pages-admin-generate-account-js": () => import("./../../../src/pages/admin/generate-account.js" /* webpackChunkName: "component---src-pages-admin-generate-account-js" */),
  "component---src-pages-admin-news-editor-js": () => import("./../../../src/pages/admin/news-editor.js" /* webpackChunkName: "component---src-pages-admin-news-editor-js" */),
  "component---src-pages-admin-news-js": () => import("./../../../src/pages/admin/news.js" /* webpackChunkName: "component---src-pages-admin-news-js" */),
  "component---src-pages-admin-pict-manager-js": () => import("./../../../src/pages/admin/pict-manager.js" /* webpackChunkName: "component---src-pages-admin-pict-manager-js" */),
  "component---src-pages-blog-post-generic-public-blog-js": () => import("./../../../src/pages/blog-post/generic-public-blog.js" /* webpackChunkName: "component---src-pages-blog-post-generic-public-blog-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-noticias-js": () => import("./../../../src/pages/noticias.js" /* webpackChunkName: "component---src-pages-noticias-js" */),
  "component---src-pages-politica-de-privacidade-js": () => import("./../../../src/pages/politica-de-privacidade.js" /* webpackChunkName: "component---src-pages-politica-de-privacidade-js" */),
  "component---src-pages-portifolio-js": () => import("./../../../src/pages/portifolio.js" /* webpackChunkName: "component---src-pages-portifolio-js" */),
  "component---src-pages-sessao-expirada-js": () => import("./../../../src/pages/sessao-expirada.js" /* webpackChunkName: "component---src-pages-sessao-expirada-js" */),
  "component---src-pages-sobre-nos-js": () => import("./../../../src/pages/sobre-nos.js" /* webpackChunkName: "component---src-pages-sobre-nos-js" */),
  "component---src-pages-solucoes-js": () => import("./../../../src/pages/solucoes.js" /* webpackChunkName: "component---src-pages-solucoes-js" */),
  "component---src-pages-user-account-js": () => import("./../../../src/pages/user/account.js" /* webpackChunkName: "component---src-pages-user-account-js" */),
  "component---src-pages-user-email-verify-js": () => import("./../../../src/pages/user/email-verify.js" /* webpackChunkName: "component---src-pages-user-email-verify-js" */),
  "component---src-pages-user-password-recovery-js": () => import("./../../../src/pages/user/password-recovery.js" /* webpackChunkName: "component---src-pages-user-password-recovery-js" */),
  "component---src-pages-user-signin-js": () => import("./../../../src/pages/user/signin.js" /* webpackChunkName: "component---src-pages-user-signin-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */)
}

